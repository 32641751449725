import React from 'react';
import PropTypes from 'prop-types';

// Utilities
import kebabCase from 'lodash/kebabCase';

// Components
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import {
  Breadcrumb,
  Container,
  Divider,
  Header,
  Icon,
  Item,
} from 'semantic-ui-react';

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Container>
    <Breadcrumb size="massive">
      <Breadcrumb.Section href="/">Home</Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section href="/blog">Blog</Breadcrumb.Section>
      <Breadcrumb.Divider />
      <Breadcrumb.Section active>Tags</Breadcrumb.Section>
    </Breadcrumb>
    <Divider horizontal>
      <Header as="h3">
        <Icon name="pin" />
        { `Tags`}
      </Header>
    </Divider>
    <Item.Group link className="blog-post-items">
      {group.map(tag => (
        <Link key={tag.fieldValue} to={`/blog/tags/${kebabCase(tag.fieldValue)}/`}>
          <Item key={tag.fieldValue}>
            {tag.fieldValue} ({tag.totalCount})
          </Item>
        </Link>
      ))}
    </Item.Group>
  </Container>
);

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
